<template>
  <div id="TicketDetailsAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Ticket</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            <BackIcon /> <b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Ref# {{ this.$route.params.ref }}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <TicketStatus :status="ticket.status.slug" :name="ticket.status.name" />
                      </td>
                    </tr>
                    <tr>
                      <th><CategoryModal :ticket="ticket" @categoryChangeSuccess="categoryChangeSuccess" />:</th>
                      <td>{{ ticket.category.name }}</td>
                    </tr>
                    <tr>
                      <th>Subcategory:</th>
                      <td>{{ ticket.subCategory.name }}</td>
                    </tr>
                    <tr>
                      <th>CreatedBy:</th>
                      <td></td>
                    </tr>
                    <!-- student details -->
                    <StudentInfoOnTicket
                      :user="ticket.user"
                      :faculty="ticket.user.faculty.name"
                      :programme="ticket.user.programme.name"
                      :studycenter="ticket.user.studycenter.name"
                    />
                    <!--  -->
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Source:</th>
                      <td>Web</td>
                    </tr>
                    <tr>
                      <th>Priority</th>
                      <td>Normal</td>
                    </tr>
                    <tr>
                      <th>Created Date</th>
                      <td>{{ ticket.createdAt | formatDateTime }}</td>
                    </tr>
                    <TicketStatusDate :ticket="ticket" />
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Status buttons -->
            <div class="toolbuttons">
              <TicketHistoryModal :ticket="ticket" />
              <span v-if="authUser.Role === 'admin'">
                <re-assign-ticket-modal :ticket="ticket" path="/admin/users?role=agent"></re-assign-ticket-modal>
              </span>
              <a :href="resultLink" class="btn btn-sm btn-outline-info" target="_blank">View result</a>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-body">
              <ul class="timeline">
                <li>
                  <i class="fa fa-envelope bg-dark"></i>
                  <div class="timeline-item">
                    <span class="time">
                      <i class="far fa-clock"></i>
                      {{ ticket.createdAt | formatDateTime }}
                    </span>
                    <h3 class="timeline-header">
                      <span class="">{{ ticket.user.name }}'s</span> message
                    </h3>
                    <div class="timeline-body">
                      <h4>{{ ticket.title }}</h4>
                      <div v-html="ticket.body"></div>
                    </div>
                    <div class="timeline-footer" v-if="ticket.attachments.length > 0">
                      <!--  -->
                      <AttachmentsView :attachments="ticket.attachments"></AttachmentsView>
                      <!--  -->
                    </div>
                  </div>
                </li>

                <!-- THREAD  -->
                <li v-for="thread in ticket.threads" :key="thread.uuid">
                  <i v-if="thread.user.email == ticket.user.email" class="fa fa-share bg-danger"></i>
                  <i v-if="thread.user.email != ticket.user.email" class="fa fa-reply bg-primary"></i>

                  <div class="timeline-item">
                    <span class="time">
                      <i class="far fa-clock"></i>
                      {{ thread.createdAt | formatDateTime }}
                    </span>
                    <h3 class="timeline-header">
                      <span v-if="thread.user.email == ticket.user.email">
                        <span class="">{{ thread.user.name }}'s</span> response
                      </span>
                      <span v-else-if="thread.user.email != ticket.user.email">
                        <span class="">{{ thread.user.name }}'s</span> response
                      </span>
                    </h3>
                    <div class="timeline-body">
                      <div v-html="thread.body"></div>
                    </div>
                    <div class="timeline-footer" v-if="thread.attachments.length > 0">
                      <!--  -->
                      <AttachmentsView :attachments="thread.attachments"></AttachmentsView>
                      <!--  -->
                    </div>
                  </div>
                </li>

                <!-- REPLY FORM -->
                <!-- <li>
            <i class="far fa-paper-plane bg-dark"></i>
            <div class="timeline-item">
              <h3 class="timeline-header">Reply</h3>
              <div class="timeline-body">
                <!-- replay form
                <ReplyForm :ticketRef="ticket.ref" :userEmail="authUser.email" @newThread="newThread"></ReplyForm>
                <!-- replay form
              </div>
            </div>
                    </li>-->

                <li>
                  <i class="fa fa-clock bg-dark"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ReplyForm from '../../../components/ReplyForm'
import BackIcon from '../../../components/BackIcon.vue'
import CategoryModal from '@/components/CategoryModal.vue'
import TicketStatus from '../../../components/TicketStatus.vue'
import TicketStatusDate from '../../../components/TicketStatusDate.vue'
import TicketHistoryModal from '../../../components/TicketHistoryModal.vue'
import StudentInfoOnTicket from '../../../components/StudentInfoOnTicket.vue'
import ReAssignTicketModal from '../../../components/ReAssignTicketModal.vue'
import AttachmentsView from '@/components/AttachmentsView.vue'

export default {
  name: 'TicketDetailsAdmin',

  data() {
    return {
      ticket: {
        createdAt: '',
        status: { name: '' },
        category: { name: '' },
        subCategory: { name: '' },
        user: { name: '', faculty: { name: '' }, programme: { name: '' }, studycenter: { name: '' } },
        attachments: [],
        threads: []
      },
      resultLink: ''
    }
  },

  watch: {
    '$route.params.ref': function(ref) {
      this.loadTicket(ref)
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    this.loadTicket(this.$route.params.ref)
    window.scrollTo(0, 0)
  },

  methods: {
    loadTicket(ref) {
      let loading = this.$loading.show()
      axios
        .get('/user/ticket/' + ref)
        .then(res => {
          if (res.data.status == 'success') {
            this.ticket = res.data.ticket
            this.resultLink = res.data.resultLink
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
            this.$router.push({ name: 'Home' })
          }
          loading.hide()
        })
        .catch(error => {})
    },

    newThread(e) {
      this.ticket.threads.push(e)
    },

    categoryChangeSuccess(cat) {
      this.ticket.category.name = cat.categoryName
      this.ticket.subCategory.name = cat.subcategoryName
    }
  },

  computed: {
    topCategoryLabel() {
      return this.$store.state.client.topCategoryLabel
    },
    subCategoryLabel() {
      return this.$store.state.client.subCategoryLabel
    },
    authUser() {
      return this.$store.state.authUser
    }
  },

  components: {
    ReplyForm,
    BackIcon,
    TicketStatus,
    TicketStatusDate,
    TicketHistoryModal,
    StudentInfoOnTicket,
    ReAssignTicketModal,
    CategoryModal,
    AttachmentsView
  }
}
</script>
