<template>
  <div class="noun-top">
    <div class="logo">
      <img alt="NOUN Logo" src="../assets/noun-logo-2.png" width="60" />
    </div>
    <div class="name">
      <div class="university-name">National Open University of Nigeria</div>
      <div class="platform-name">Support Ticketing Platform</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NOUNTop'
}
</script>

<style>
.noun-top {
  width: 100vw;
  height: auto;
  background-color: #068a50;
  display: flex;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  align-items: center;
}

.noun-top .logo {
  margin-left: 2rem;
  /*  img {
      width: 5rem;
    } */
}

.noun-top .name {
  margin-left: 1rem;
  color: #f9f9f9;
}

.noun-top .name .university-name {
  font-weight: bold;
  font-size: 1.3rem;
}
.noun-top .name .platform-name {
  font-size: 1.1rem;
}

@media (max-width: 576px) {
  .noun-top .logo {
    margin-left: 0.7rem;
  }
  .noun-top .logo img {
    width: 3rem;
    height: auto;
  }

  .noun-top .name {
    margin-left: 0.7rem;
  }
  .noun-top .name .university-name {
    font-size: 0.9rem;
  }
  .noun-top .name .platform-name {
    font-size: 0.7rem;
  }
}
</style>
