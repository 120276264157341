<template>
  <div>
    <!-- Form -->
    <div class="container" v-show="formShow">
      <div class="row">
        <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-12">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> My Tickets</div>
            <div class="card-body">
              <form @submit.prevent="submit">
                <!--  -->
                <div class="form-group">
                  <label for="matric">Matric Number</label>
                  <input type="text" v-model="matric" class="form-control form-control-lg" placeholder="e.g. NOU123456789" :class="{ 'is-invalid': $v.matric.$error, 'is-valid': !$v.matric.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.matric.required">Matric is required.</span>
                    <span v-if="!$v.matric.minLength">Matric must be at least {{ $v.matric.$params.minLength.min }} letters.</span>
                    <span v-if="!$v.matric.maxLength">Matric must be at most {{ $v.matric.$params.maxLength.max }} letters.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="matric">Phone Number</label>
                  <input type="text" v-model="phone" class="form-control form-control-lg" placeholder="e.g. 08011223344" :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.phone.required">Phone is required.</span>
                    <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} letters.</span>
                    <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} letters.</span>
                  </div>
                </div>
                <!--  -->
                <button v-if="!spinner" class="btn btn-danger btn-lg" type="submit"><i class="far fa-paper-plane mr-1"></i> Send</button>
                <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ticket ListPublic -->
    <div class="container-fluid" v-show="!formShow">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-header"><BackIcon /><b-icon-stickies v-if="!$routerHistory.hasPrevious()"></b-icon-stickies> My Tickets {{ totalTickets }}</div>
            <div class="card-body">
              <ListPublic v-if="!spinner" :tickets="tickets" routeName="TicketDetails"></ListPublic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ListPublic from '@/components/ListPublic.vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'MyTickets',

  data() {
    return {
      formShow: true,
      spinner: false,
      matric: '',
      phone: '',

      tickets: [],
      status: '',
      totalTickets: ''
    }
  },

  methods: {
    submit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      formData.append('matric', this.matric)
      formData.append('phone', this.phone)

      axios.post('/tickets', formData).then(res => {
        if (res.data.status == 'success') {
          this.formShow = false
          this.tickets = res.data.tickets
          this.totalTickets = res.data.totalTickets
        } else {
          this.$swal('Oops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  components: {
    ListPublic,
    BackIcon
  },

  validations: {
    matric: { required, minLength: minLength(12), maxLength: maxLength(12) },
    phone: { required, minLength: minLength(11), maxLength: maxLength(11) }
  }
}
</script>

<style></style>
