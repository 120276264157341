<template>
  <div class="">
    <div class="sidebar" :class="compress ? 'compress' : ''">
      <div class="logo-details">
        <!-- <i class="bx bxl-c-plus-plus"></i> -->
        <div class="logo_icon">
          <img src="../assets/noun-logo.png" alt="" width="50" height="50" />
        </div>
        <div class="logo_name">Support</div>
      </div>
      <ul class="nav-links">
        <!-- Dashboard -->
        <li>
          <router-link :to="{ name: 'DashboardManager' }">
            <b-icon-house-door></b-icon-house-door>
            <span class="link_name">Dashboard</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Dashboard</a></li>
          </ul>
        </li>

        <!-- Category -->
        <li @click="showSubMenu('Tickets')" :class="menu == 'Tickets' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-stickies></b-icon-stickies>
              <span class="link_name">Tickets</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Tickets</a></li>
            <li><router-link :to="{ name: 'TicketsManager2', params: { status: 'open' } }">Open</router-link></li>
            <li><router-link :to="{ name: 'TicketsManager2', params: { status: 'in_progress' } }">InProgress</router-link></li>
            <li><router-link :to="{ name: 'TicketsManager2', params: { status: 'escalated' } }">Escalated</router-link></li>
            <li><router-link :to="{ name: 'TicketsManager2', params: { status: 'closed' } }">Closed</router-link></li>
            <li><router-link :to="{ name: 'TicketsManager2', params: { status: 'reopened' } }">Reopened</router-link></li>
            <li><router-link :to="{ name: 'TicketsManager' }">All</router-link></li>
          </ul>
        </li>

        <li>
          <router-link :to="{ name: 'FAQManager' }">
            <b-icon-question-circle></b-icon-question-circle>
            <span class="link_name">FAQs</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">FAQs</a></li>
          </ul>
        </li>

        <!-- Categories -->
        <li>
          <router-link :to="{ name: 'CategoriesManager' }">
            <b-icon-layers></b-icon-layers>
            <span class="link_name">Categories</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Categories</a></li>
          </ul>
        </li>

        <!-- Subcategories -->
        <li>
          <router-link :to="{ name: 'SubCategoriesManager' }">
            <b-icon-layers-half></b-icon-layers-half>
            <span class="link_name">Subcategories</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Subcategories</a></li>
          </ul>
        </li>

        <!-- Custom Roles -->
        <li>
          <router-link :to="{ name: 'CustomRolesManager' }">
            <b-icon-folder></b-icon-folder>
            <span class="link_name">Custom Roles</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Custom Roles</a></li>
          </ul>
        </li>

        <!-- Custom Roles -->
        <li @click="showSubMenu('Users')" :class="menu == 'Users' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-people></b-icon-people>
              <span class="link_name">Users</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Users</a></li>
            <li><router-link :to="{ name: 'UsersManager', params: { role: 'admin' } }">Admin</router-link></li>
            <li><router-link :to="{ name: 'UsersManager', params: { role: 'manager' } }">Managers</router-link></li>
            <li><router-link :to="{ name: 'UsersManager', params: { role: 'agent' } }">Agents</router-link></li>
            <li><router-link :to="{ name: 'UsersManager', params: { role: 'custom' } }">Customs</router-link></li>
            <li><router-link :to="{ name: 'UsersManager', params: { role: 'supervisor' } }">Supervisors</router-link></li>
            <li><router-link :to="{ name: 'StudentsManager' }">Students</router-link></li>
          </ul>
        </li>

        <li>
          <router-link :to="{ name: 'UsersLogManager' }">
            <b-icon-journal-code></b-icon-journal-code>
            <span class="link_name">Audit Trail</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Audit Trail</a></li>
          </ul>
        </li>
        <!-- profile -->
        <li>
          <div class="profile-details">
            <router-link :to="{ name: 'ProfileManager' }">
              <div class="profile-content">
                <img src="../assets/default.png" alt="profile" width="52" height="52" />
              </div>

              <div class="name-job">
                <div class="profile_name">{{ authUser.Name }}</div>
                <div class="job">{{ authUser.Role }}</div>
              </div>
            </router-link>

            <b-icon-box-arrow-left title="Logout" @click="Logout"></b-icon-box-arrow-left>
            <!-- <i class="bx bx-log-out" ></i> -->
          </div>
        </li>
      </ul>
    </div>

    <section class="home-section">
      <div class="home-content">
        <!-- <i class="bx bx-menu" @click="toggleMenu()"></i> -->
        <b-icon-list @click="toggleMenu()"></b-icon-list>
        <span class="text"></span>
      </div>
      <div class="cont">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ManagerLayout',

  data() {
    return {
      compress: false,
      menu: ''
    }
  },

  mounted() {
    if (window.innerWidth < 768) {
      this.compress = true
    }
  },

  created() {
    window.addEventListener('resize', this.screenResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.screenResize)
  },

  methods: {
    screenResize(e) {
      //console.log(e.srcElement.innerWidth);
      /* console.log(screen.width); */
      if (e.srcElement.innerWidth < 768) {
        this.compress = true
      } else {
        this.compress = false
      }
    },

    toggleMenu() {
      this.compress = !this.compress
    },

    showSubMenu(menu) {
      if (this.menu == menu) {
        this.menu = ''
      } else {
        this.menu = menu
      }
    },

    Logout() {
      axios.post('/auth/logout').then(res => {
        if (res.data.status == 'success') {
          this.$store.state.authUser = {}
          this.$store.state.authToken = ''
          window.localStorage.removeItem('log_uuid')
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY
          this.$swal('', 'Logged out successfully.', 'info')
          this.$router.push('/login')
        }
      })
    }

    /*  */
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped></style>
