<template>
  <div class="container-fluid" id="EditSubCategoryAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Update</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">{{ name }}</div>
          <div class="card-body">
            <div class="col-md-8">
              <h3>Please provide Agent name</h3>
              <!-- Create form -->
              <form @submit.prevent="updateSubCategory">
                <div class="form-group">
                  <label for="name">
                    Select Category
                    <span class="text-danger">*</span>
                  </label>
                  <select @change="changeTopCategory($event)" class="form-control">
                    <option
                      v-for="(cat, index) in categories"
                      :key="index"
                      :value="index"
                      :selected="cat.uuid == subCategory.category.uuid ? 'selected' : ''"
                      >{{ cat.name }}</option
                    >
                  </select>
                </div>

                <div class="form-group">
                  <label for="name">
                    Subcategory name
                    <span class="text-danger">*</span>
                  </label>
                  <input type="text" v-model="name" class="form-control" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.name.required">Subcategory name is required.</span>
                    <span v-if="!$v.name.minLength">Subcategory name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="name">Subcategory description</label>
                  <textarea v-model="description" class="form-control" rows="3" />
                  <!-- <div class="invalid-feedback">
                          <span v-if="!$v.description.minLength">Supervisor description must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                      </div>-->
                </div>

                <!-- Instruction -->
                <div class="form-group">
                  <label for="instruction">Solution Instructions</label>
                  <vue-editor v-model="instruction" :editorToolbar="editorToolbar"></vue-editor>
                  <!-- <div class="text-danger text-xs" v-show="$v.instruction.$error">
                    <span v-if="!$v.instruction.required">Solution Instructions is required.</span>
                    <span v-if="!$v.instruction.minLength">
                      Solution Instructions must be at least {{ $v.instruction.$params.minLength.min }} letters.
                    </span>
                  </div> -->
                </div>

                <!--  -->

                <button class="btn btn-danger btn-block">Update Subcategory</button>
              </form>
              <!-- / Create form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'EditSubCategoryAdmin',

  data() {
    return {
      name: '',
      description: '',
      instruction: '',
      categories: [],
      subCategory: {},
      facultyUUID: '',
      instruction: '',

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get(`/admin/sub-category/${this.$route.params.uuid}`).then(res => {
      this.name = res.data.subCategory.name
      this.description = res.data.subCategory.description
      this.instruction = res.data.subCategory.instruction
      this.facultyUUID = res.data.subCategory.category.uuid
      this.subCategory = res.data.subCategory
    })

    axios.get('/admin/categories').then(res => {
      this.categories = res.data.categories
    })
    loading.hide()
  },

  methods: {
    updateSubCategory() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      //this.facultyUUID = this.subCategory.category.uuid;
      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('instruction', this.instruction)
      formData.append('topCategoryUUID', this.facultyUUID)
      formData.append('uuid', this.$route.params.uuid)

      axios.post('admin/sub-category/edit', formData).then(res => {
        if (res.data.status == 'success') {
          //res.data.category.subCategories = [];
          //this.categories.push(res.data.category);
          this.$swal('', `${this.name} updated successfully`, 'success')
          this.$router.push({ name: 'SubCategoriesAdmin' })
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    },

    changeTopCategory(e) {
      this.facultyUUID = this.categories[e.target.value].uuid
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  validations: {
    name: { required, minLength: minLength(2) }
    //description: { minLength: minLength(2) }
  },

  components: {
    VueEditor
  }
}
</script>
