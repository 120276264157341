<template>
  <div>
    <span>Attachment(s)</span>
    <br />
    <div v-for="(attachment, index) in attachments" :key="attachment.uuid">
      <iframe v-if="attachment.name.includes('.pdf')" :src="`${attachment.name}#toolbar=0&view=FitH`" @contextmenu.prevent style="width: 100%;" />
      <img v-else :src="attachment.name" width="100%" />
      <a :href="attachment.name" class="text-danger" target="_blank">
        <b-icon-paperclip></b-icon-paperclip>
        Download
        <!-- {{ attachment.original_name }} -->
      </a>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttachmentsView',
  props: {
    attachments: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped></style>
