const dropzoneOptions = {
  thumbnailHeight: 80,
  maxFilesize: 2 ** 30, // 1073741824 bytes  1GB
  url: process.env.VUE_APP_API_URL + '/cdn/upload', //"http://127.0.0.1:7370/cdn/upload",
  headers: { apikey: process.env.VUE_APP_API_KEY },
  acceptedFiles:
    //"image/*, text/csv, text/plain, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation",
    'image/*, application/pdf',
  myCustomContent: `
    <span class="vdContent-title">Drag and drop to upload files here!</span>
    <span class="vdContent-file">
        <b>Supported file:</b> IMAGE/PHOTO, PDF
    </span>`,
  // <b>Supported file:</b> IMAGE/PHOTO, MS-WORD, MS-EXCEL, MS-POWERPOINT, PDF, TXT, CSV, ODP, ODS, ODT
  addRemoveLinks: true
}
export default dropzoneOptions
