<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12 col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-box-arrow-in-right v-if="!$routerHistory.hasPrevious()"></b-icon-box-arrow-in-right> Login</div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label for="email">Email or Matric number</label>
                <input v-model="user.username" type="text" class="form-control form-control-lg" placeholder="Email or Matric" :class="{ 'is-invalid': $v.user.username.$error, 'is-valid': !$v.user.username.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.user.username.required">Email or Matric is required.</span>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="email">Password</label>
                <input v-model="user.password" type="password" class="form-control form-control-lg" placeholder="Password" :class="{ 'is-invalid': $v.user.password.$error, 'is-valid': !$v.user.password.$invalid }" />
                <div class="invalid-feedback">
                  <span v-if="!$v.user.password.required">Password is required.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <button v-if="!spinner" type="submit" class="btn btn-danger">Login</button>
                  <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>
                </div>
                <div class="col-6 text-right">
                  <!--  <router-link :to="{ name: 'SignUp' }">Sign up</router-link>
                  <br /> -->
                  <router-link to="/password-reset">Forgot Password?</router-link>
                  <br />
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'MyTickets',

  components: { BackIcon },

  data() {
    return {
      spinner: false,
      user: {
        username: '',
        password: ''
      }
    }
  },

  methods: {
    submit() {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }
      let formData = new FormData()
      formData.append('username', this.user.username)
      formData.append('password', this.user.password)
      axios.post('/auth', formData).then(res => {
        if (res.data.status == 'success') {
          this.$store.state.authUser = res.data.user
          this.$store.state.authToken = res.data.token
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
          window.localStorage.setItem('log_uuid', res.data.log) // this is auth log uuid for server to retrieve JWT token
          if (res.data.requirePasswordChanged) {
            this.$router.push(res.data.user.Role + '/change-password')
            return
          }
          this.$router.push(res.data.user.Role)
          return
        } else {
          this.$store.state.authUser = {}
          this.$swal('', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  validations: {
    user: {
      username: { required },
      password: { required }
    }
  }
}
</script>

<style scoped>
/* input {
  font-size: 1rem;
} */
</style>
