<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /><b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Open New Ticket</div>
          <div class="card-body">
            <div v-if="spinner1" class="text-center">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>

            <div v-if="!spinner1">
              <p>
                Please enter your matric number, select the issue category, valid phone number and message body.
                <!-- <br /> -->
                <b>NOTE:</b> You will be contacted and notified via NOUN email (e.g. NOU123456789@noun.edu.ng) and phone provided.
              </p>
              <p>
                You can check out
                <a href="https://nou.edu.ng/faqs" target="_blank" style="color: #068a50; font-weight: bold;">frequently asked questions.</a>
              </p>

              <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="matric">Matric Number</label>
                      <input
                        type="text"
                        v-model="matric"
                        class="form-control form-control-lg"
                        placeholder="e.g. NOU123456789"
                        :class="{ 'is-invalid': $v.matric.$error, 'is-valid': !$v.matric.$invalid }"
                      />
                      <div class="invalid-feedback">
                        <span v-if="!$v.matric.required">Matric is required.</span>
                        <span v-if="!$v.matric.minLength">Matric must be at least {{ $v.matric.$params.minLength.min }} letters.</span>
                        <span v-if="!$v.matric.maxLength">Matric must be at most {{ $v.matric.$params.maxLength.max }} letters.</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="matric">Phone number</label>
                      <input
                        type="text"
                        v-model="phone"
                        class="form-control form-control-lg"
                        placeholder="e.g. 08011223344"
                        :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }"
                      />
                      <div class="invalid-feedback">
                        <span v-if="!$v.phone.required">Phone is required.</span>
                        <span v-if="!$v.phone.numeric">Phone must be numeric.</span>
                        <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} digits.</span>
                        <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} digits.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label for="matric">Email</label>
                      <input type="text" v-model="email" class="form-control form-control-lg" placeholder="e.g. abc@gmail.com" :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }" />
                      <div class="invalid-feedback">
                        <span v-if="!$v.email.required">Email is required.</span>
                        <span v-if="!$v.email.email">Email must be valid email address.</span>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="category">Category</label>
                      <select @change="topSelected($event)" class="form-control form-control-lg">
                        <option value="Select..." selected>Select...</option>
                        <option v-for="(item, index) in categories" :key="index" :value="index">{{ item.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="category">Subcategory</label>
                      <select
                        v-model="category"
                        @change="subcategorySelectedFunc($event)"
                        class="form-control form-control-lg"
                        :class="{ 'is-invalid': $v.category.$error, 'is-valid': !$v.category.$invalid }"
                      >
                        <option value="Select..." selected>Select...</option>
                        <option v-for="(item, index) in subCategories" :key="index" :value="item.uuid">{{ item.name }}</option>
                        <!--  <optgroup v-for="(cat, i) in categories" :key="i" :label="cat.name">
                          <option v-for="(sub, j) in cat.subCategories" :key="j" :value="sub.uuid">{{ sub.name }}</option>
                        </optgroup> -->
                        <!-- End option group -->
                      </select>
                      <div class="invalid-feedback">
                        <span v-if="!$v.category.required">Issue category is required.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="message">Message</label>
                  <vue-editor v-model="message" :editorToolbar="editorToolbar"></vue-editor>
                  <div class="text-danger text-xs" v-show="$v.message.$error">
                    <span v-if="!$v.message.required">Message is required.</span>
                    <span v-if="!$v.message.minLength">Message must be at least {{ $v.message.$params.minLength.min }} letters.</span>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <i class="fa fa-paperclip"></i> Attachment
                  <input type="file" ref="file" multiple="multiple" />

                  <p class="help-block"><b>File Type:</b> jpg,jpeg,png,gif,pdf,doc,docx,xls,xlsx | <b>Max file size:</b> 5MB</p>
                </div> -->
                <div class="form-group">
                  <b-icon-paperclip></b-icon-paperclip> <label for="att">Attachment(s)</label>
                  <vue-dropzone
                    id="dropzone"
                    ref="myVueDropzone"
                    :useCustomSlot="true"
                    :options="dropzoneOptions"
                    @vdropzone-error="uploadError"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-file-added="uploadFileAdded"
                  >
                    <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
                  </vue-dropzone>
                </div>

                <button v-if="!spinner" class="btn btn-danger btn-lg" type="submit"><i class="far fa-paper-plane mr-1"></i> Send</button>
                <b-spinner v-if="spinner" variant="success" label="Spinning"></b-spinner>

                <!--  -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <FaqModal uuid="ccd485c9-760e-4968-ab91-8930c35c0131" /> -->

    <!-- subcategory instruction modal -->
    <b-modal id="SubcategoryInstruction" size="lg" hide-header hide-footer no-close-on-backdrop centered>
      <div>
        <label>{{ subcategorySelected.name }}</label>
        <h2 class="mb-4">Solution instructions:</h2>
        <div v-html="subcategorySelected.instruction"></div>
        <hr />
        <div class="mt-2">
          <p class="text-info">If you are dissatisfied, please click continue to open a new ticket.</p>
          <div style="display: flex; justify-content: space-between;">
            <button class="btn btn-success btn-md" @click="$bvModal.hide('SubcategoryInstruction')">Continue to ticket</button>
            <a href="/" class="btn btn-secondary btn-md">I'm satisfied</a>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- / subcategory instruction -->

    <!-- Announcement Modal  -->
    <b-modal id="AnnouncementModal" size="lg" hide-header hide-footer no-close-on-backdrop centered>
      <div>
        <label class="mb-5">Announcement</label>
        <div v-html="announcement.body"></div>

        <div class="mt-5">
          <button class="btn btn-link btn-md" @click="$bvModal.hide('AnnouncementModal')">Close</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { required, email, numeric, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import BackIcon from '@/components/BackIcon.vue'
import FaqModal from '@/components/FaqModal.vue'
import vue2Dropzone from 'vue2-dropzone'
import dropzoneOptions from '../helper/dropzoneOptions'

export default {
  name: 'TicketCreate',

  data() {
    return {
      spinner: false,
      spinner1: false,
      categories: [],
      subCategories: [],
      announcement: {},

      // Form data
      matric: '',
      email: '',
      phone: '',
      category: '',
      message: '',

      attachments: [],

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],

      subcategorySelected: {}
    }
  },

  mounted() {
    this.spinner1 = true
    axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categoriesDirect
        this.announcement = res.data.announcement
        if (this.announcement.body !== '') {
          this.$bvModal.show('AnnouncementModal')
        }
      }
      this.spinner1 = false
    })
  },

  methods: {
    handleSubmit(e) {
      this.spinner = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let formData = new FormData()
      /* let allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx']

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i]
        formData.append('files[' + i + ']', file)
        // Form Validate ...
        let fileName = file.name
        let fileExtension = fileName.replace(/^.*\./, '')
        if (allowedExtensions.includes(fileExtension) == false) {
          let msg = '.' + fileExtension + ' file not allowed. Please replace ' + file.name
          this.$swal('Oops!', msg, 'error')
          this.spinner = false
          return
        }
        if (file.size > 5000000) {
          let msg = file.name + ' is larger than the allowed file size of 5MB.'
          this.$swal('Oops!', msg, 'error')
          this.spinner = false
          return
        }
      } */

      //formData.append('email', this.email)
      formData.append('phone', this.phone)
      formData.append('matric', this.matric)
      formData.append('subCategoryID', this.category)
      formData.append('message', this.message)
      formData.append('source', 'web')
      formData.append('priority', 'normal')
      formData.append('originURL', window.location.origin)
      formData.append('attachments', JSON.stringify(this.attachments))

      axios
        .post('/ticket/create', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.$v.$reset()
            //empty form
            this.matric = ''
            this.category = ''
            this.message = ''
            this.attachments = []
            this.$refs.myVueDropzone.removeAllFiles()
            // fee back
            this.$swal(res.data.title, res.data.message, 'success')
            this.$router.push({ name: 'TicketDetails', params: { ref: res.data.ticketID } })
          } else {
            this.$swal('Oops!', res.data.message, 'error')
          }
          this.spinner = false
        })
        .catch(err => {
          this.spinner = false
        })
    },

    topSelected(e) {
      if (e.target.value == 'Select...') {
        this.showSubCat = false
        return
      }
      this.subCategories = this.categories[e.target.value].subCategories
      this.showSubCat = true
      //this.category = this.categories[e.target.value].uuid
    },

    subcategorySelectedFunc(e) {
      if (e.target.value == 'Select...') {
        return
      }
      let uuid = e.target.value
      this.subcategorySelected = this.subCategories.find(function(item) {
        return item.uuid == uuid
      })
      if (this.subcategorySelected.instruction != '') {
        this.$bvModal.show('SubcategoryInstruction')
      }
    },

    uploadFileAdded(file) {
      this.spinner = true // vdropzone-file-added( file)
    },
    uploadError(file) {
      this.spinner = false // vdropzone-file-added( file)
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },
    uploadSuccess(file, response) {
      this.spinner = false //vdropzone-success( file, response)
      this.attachments.push({
        name: response.link,
        size: file.size,
        original_name: file.name
      })
    }
  },

  computed: {
    topCategoryLabel() {
      return [] //this.$store.state.client.topCategoryLabel;
    },
    subCategoryLabel() {
      return [] //this.$store.state.client.subCategoryLabel;
    },
    dropzoneOptions() {
      return dropzoneOptions
    }
  },

  validations: {
    matric: { required, minLength: minLength(12), maxLength: maxLength(12) },
    category: { required },
    message: { required, minLength: minLength(10) },
    //email: { required, email },
    phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) }
  },

  components: {
    VueEditor,
    BackIcon,
    FaqModal,
    vueDropzone: vue2Dropzone
  }
}
</script>
