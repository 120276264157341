import { render, staticRenderFns } from "./StudentsPurgeAdmin.vue?vue&type=template&id=97c7674a&scoped=true&"
import script from "./StudentsPurgeAdmin.vue?vue&type=script&lang=js&"
export * from "./StudentsPurgeAdmin.vue?vue&type=script&lang=js&"
import style0 from "./StudentsPurgeAdmin.vue?vue&type=style&index=0&id=97c7674a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c7674a",
  null
  
)

export default component.exports