<template>
  <div id="User">
    <NOUNTop v-show="!this.$store.state.webview" />
    <b-navbar toggleable="lg" type="light" variant="light">
      <router-link class="navbar-brand text-muted" :to="{ name: 'HomeUser' }">User</router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link :to="{ name: 'HomeUser' }" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li>

          <li>
            <a class="nav-link" :href="authUser.ResultLink" target="_blank">
              <b-icon-file-earmark-text></b-icon-file-earmark-text>
              Result statement
            </a>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- Notification -->
          <notifications />
          <!-- User profile -->
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name }} </template>

            <b-dropdown-item :to="{ name: 'ProfileUser' }">
              <b-icon-person></b-icon-person>
              Profile
            </b-dropdown-item>

            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import NOUNTop from '@/components/NOUNTop.vue'
import Logout from '@/components/Logout.vue'
import Notifications from '../components/Notifications.vue'

export default {
  name: 'UserLayout',

  components: {
    NOUNTop,
    Logout,
    Notifications
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
