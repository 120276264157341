import OfficeScreen from '../layouts/OfficeScreen.vue'
import HomeOfficeScreen from '../views/officeScreen/Home.vue'
import DashboardOfficeScreen from '../views/officeScreen/Dashboard.vue'
import TicketsOfficeScreen from '../views/officeScreen/Tickets.vue'

const officeScreenRoutes = [{
    path: '/office-screen',
    //component: () => import('../layouts/OfficeScreen.vue'),
    //beforeEnter: auth.ifAuthenticated,
    component: OfficeScreen,
    children: [
        /* core */
        {
            path: '/',
            name: 'HomeOfficeScreen',
            //component: () => import('../views/officeScreen/Home.vue')
            component: HomeOfficeScreen
        },
        {
            path: 'dashboard',
            name: 'DashboardOfficeScreen',
            // component: () => import('../views/officeScreen/Dashboard.vue')
            component: DashboardOfficeScreen
        },
        {
            path: 'tickets',
            name: 'TicketsOfficeScreen',
            //component: () => import('../views/officeScreen/Tickets.vue'),
            component: TicketsOfficeScreen
        },
    ]
}]

export default officeScreenRoutes