<template>
  <div class="container">
    <!-- loading -->
    <div class="row" v-if="spinner">
      <div class="col-lg-10 offset-lg-1 col-sm-12 offset-sm-12">
        <div class="card">
          <div class="card-header"><BackIcon /><b-icon-sticky v-if="!$routerHistory.hasPrevious()"></b-icon-sticky> Ticket Ref# (loading...)</div>
          <div class="card-body">
            <div class="text-center">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- actual content -->
    <div v-if="!spinner" class="row">
      <div class="col-lg-10 offset-lg-1 col-sm-12 offset-sm-12">
        <div class="card">
          <div class="card-header"><BackIcon /> Ticket Ref# {{ this.$route.params.ref }}</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <TicketStatus :status="ticket.status.slug" :name="ticket.status.name" />
                      </td>
                    </tr>
                    <tr>
                      <th><CategoryModal :ticket="ticket" @categoryChangeSuccess="categoryChangeSuccess" />:</th>
                      <td>{{ ticket.category.name }}</td>
                    </tr>
                    <tr>
                      <th>Subcategory:</th>
                      <td>{{ ticket.subCategory.name }}</td>
                    </tr>
                    <tr>
                      <th>CreatedBy:</th>
                      <td></td>
                    </tr>
                    <!-- student details -->
                    <StudentInfoOnTicket :user="ticket.user" :faculty="ticket.user.faculty.name" :programme="ticket.user.programme.name" :studycenter="ticket.user.studycenter.name" />
                    <!--  -->
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <th>Source:</th>
                      <td>Web</td>
                    </tr>
                    <tr>
                      <th>Priority</th>
                      <td>Normal</td>
                    </tr>
                    <tr>
                      <th>Created Date</th>
                      <td>{{ ticket.createdAt | formatDateTime }}</td>
                    </tr>
                    <TicketStatusDate :ticket="ticket" />
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Status buttons -->
            <div class="toolbuttons">
              <button v-if="ticket.status.slug == 'closed'" @click="changeStatus('reopen')" class="btn btn-sm btn-info ml-1 mr-1">Reopen</button>
              <button v-if="ticket.status.slug !== 'closed' || ticket.status.slug !== 'escalated'" @click="changeStatus('close')" class="btn btn-sm btn-success ml-1 mr-1">Close</button>
              <EscalateModal v-if="ticket.status.slug !== 'closed' || ticket.status.slug !== 'escalated'" :ticket="ticket" />
              <TicketHistoryModal :ticket="ticket" />
              <a :href="resultLink" class="btn btn-sm btn-outline-info" target="_blank">View result</a>
            </div>
          </div>
        </div>
        <!-- Timeline -->
        <div class="card">
          <!-- <div class="card-header">ddd</div> -->
          <div class="card-body">
            <ul class="timeline">
              <li>
                <i class="fa fa-envelope bg-dark"><b-icon-envelope></b-icon-envelope></i>
                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ ticket.createdAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <span class="">{{ ticket.user.name }}'s</span> message
                  </h3>
                  <div class="timeline-body">
                    <h4>{{ ticket.title }}</h4>
                    <div v-html="ticket.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="ticket.attachments.length > 0">
                    <!--  -->
                    <AttachmentsView :attachments="ticket.attachments"></AttachmentsView>
                    <!--  -->
                  </div>
                </div>
              </li>

              <!-- THREAD  -->
              <li v-for="thread in ticket.threads" :key="thread.uuid">
                <i v-if="thread.user.email == ticket.user.email" class="fa fa-share bg-danger"><b-icon-arrow90deg-left></b-icon-arrow90deg-left></i>
                <i v-if="thread.user.email != ticket.user.email" class="fa fa-reply bg-primary"><b-icon-arrow90deg-right></b-icon-arrow90deg-right></i>

                <div class="timeline-item">
                  <span class="time">
                    <b-icon-clock></b-icon-clock>
                    {{ thread.createdAt | formatDateTime }}
                  </span>
                  <h3 class="timeline-header">
                    <span v-if="thread.user.email == ticket.user.email">
                      <span class="">{{ thread.user.name }}'s</span> response
                    </span>
                    <span v-else-if="thread.user.email != ticket.user.email">
                      <span class="">{{ thread.user.name }}'s</span> response
                    </span>
                  </h3>
                  <div class="timeline-body">
                    <div v-html="thread.body"></div>
                  </div>
                  <div class="timeline-footer" v-if="thread.attachments.length > 0">
                    <!--  -->
                    <AttachmentsView :attachments="thread.attachments"></AttachmentsView>
                    <!--  -->
                  </div>
                </div>
              </li>

              <!-- REPLY FORM -->
              <li>
                <i class="far fa-paper-plane bg-dark"></i>
                <div class="timeline-item">
                  <h3 class="timeline-header">Reply</h3>
                  <div class="timeline-body">
                    <!-- replay form  -->
                    <ReplyForm :ticketRef="ticket.ref" :userEmail="authUser.Email" @newThread="newThread"></ReplyForm>
                    <!-- replay form  -->
                  </div>
                </div>
              </li>

              <li>
                <i class="fa fa-clock bg-dark"><b-icon-clock></b-icon-clock></i>
              </li>
            </ul>
          </div>
        </div>
        <!-- End of timeline -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ReplyForm from '../../components/ReplyForm'
import EscalateModal from '@/components/EscalateModal.vue'
import BackIcon from '@/components/BackIcon.vue'
import CategoryModal from '@/components/CategoryModal.vue'
import TicketStatus from '@/components/TicketStatus.vue'
import TicketStatusDate from '@/components/TicketStatusDate.vue'
import TicketHistoryModal from '@/components/TicketHistoryModal.vue'
import StudentInfoOnTicket from '@/components/StudentInfoOnTicket.vue'
import AttachmentsView from '@/components/AttachmentsView.vue'

export default {
  name: 'TicketDetailsAgent',

  data() {
    return {
      ticket: {
        createdAt: '',
        status: { name: '' },
        category: { name: '' },
        subCategory: { name: '' },
        user: { name: '', faculty: { name: '' }, programme: { name: '' }, studycenter: { name: '' } },
        attachments: [],
        threads: []
      },
      // customRoles: [],
      //showEscalateModal: false,
      spinner: false,
      resultLink: ''
    }
  },

  watch: {
    '$route.params.ref': function(ref) {
      this.loadTicket(ref)
      //this.loadCustomRoles()
      window.scrollTo(0, 0)
    }
  },

  mounted() {
    this.loadTicket(this.$route.params.ref)
    // this.loadCustomRoles()
    window.scrollTo(0, 0)
  },

  methods: {
    loadTicket(ref) {
      this.spinner = true
      axios
        .get('/user/ticket/' + ref)
        .then(res => {
          if (res.data.status == 'success') {
            this.ticket = res.data.ticket
            this.resultLink = res.data.resultLink
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
            this.$router.push({ name: 'Home' })
          }
          this.spinner = false
        })
        .catch(error => {})
    },

    /*  loadCustomRoles() {
      axios
        .get('/agent/custom-roles')
        .then(res => {
          if (res.data.status == 'success') {
            this.customRoles = res.data.customRoles
          } else {
            this.$swal('Ooops!', res.data.message, 'error')
            //this.$router.push({ name: "Home" });
          }
        })
        .catch(error => {})
    }, */

    newThread(e) {
      this.ticket.threads.push(e)
    },

    changeStatus(status) {
      let loading = this.$loading.show()
      let formData = new FormData()
      formData.append('ref', this.ticket.ref)
      formData.append('status', status)
      formData.append('originURL', window.location.origin)
      axios.post('/agent/change-ticket-status', formData).then(res => {
        if (res.data.status == 'success') {
          this.ticket.status = res.data.newStatus
          //this.showEscalateModal = false;
          loading.hide()
          this.$swal('Done!', res.data.message, 'success')
          window.location.reload()
        }
      })
    },

    categoryChangeSuccess(cat) {
      this.ticket.category.name = cat.categoryName
      this.ticket.subCategory.name = cat.subcategoryName
    }

    /* escalateModal() {
      this.showEscalateModal = true;
    }, */

    /* closeModal() {
      this.showEscalateModal = false;
    }, */
  },

  computed: {
    topCategoryLabel() {
      return this.$store.state.client.topCategoryLabel
    },
    subCategoryLabel() {
      return this.$store.state.client.subCategoryLabel
    },
    authUser() {
      return this.$store.state.authUser
    }
  },

  components: { ReplyForm, EscalateModal, BackIcon, CategoryModal, TicketStatus, TicketStatusDate, TicketHistoryModal, StudentInfoOnTicket, AttachmentsView }
}
</script>

<style scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
</style>
