<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-header"><BackIcon /> <b-icon-question-circle v-if="!$routerHistory.hasPrevious()"></b-icon-question-circle> FAQs</div>
          <div class="card-body">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>

            <!-- categories -->
            <div class="categoriesWrapper">
              <p>
                <label>Categories </label>
              </p>
              <button v-for="cat in categories" @click="filterByCategory(cat.uuid)" class="btn btn-sm btn-outline-success">
                {{ cat.name }}
              </button>
            </div>
            <!-- ./ categories -->
            <p>{{ totalRecords }} records</p>
            <!--  -->
            <div class="accordion" role="tablist" v-if="!spinner">
              <b-card no-body class="mb-1" v-for="(item, index) in faqs" :key="index" style="border: none;">
                <b-card-header header-tag="header" class="p-2 faqHeader" role="tab" style="">
                  <b-button block v-b-toggle="'accordion-' + index" variant="light" class="accordion-button" style="background-color: #eff3f9">
                    <b-icon-question-circle class="questionIcon"></b-icon-question-circle>
                    <span>{{ item.question }}</span>
                  </b-button>
                </b-card-header>
                <b-collapse :id="'accordion-' + index" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div style="display: flex;  align-items: top;">
                      <b-icon-book class="bookIcon"></b-icon-book>
                      <div>
                        <p>
                          <label class="text-success" style="font-weight:bold;">{{ item.categoryName }}</label>
                        </p>
                        <div v-html="item.answer"></div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <!--  -->
            <p>
              You can check out other
              <a href="https://nou.edu.ng/faqs" target="_blank" style="color: #068a50; font-weight: bold;">frequently asked questions.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import BackIcon from '@/components/BackIcon.vue'

export default {
  name: 'Faq',

  components: { BackIcon },

  data() {
    return {
      spinner: false,
      faqs: [],
      categories: [],
      totalRecords: 0,
      faqsFullList: []
    }
  },

  mounted() {
    this.spinner = true
    axios.get('/faqs').then(res => {
      this.faqs = res.data.faqs
      this.faqsFullList = res.data.faqs
      this.totalRecords = res.data.totalRecords
      this.spinner = false
    })
    this.spinner = true
    axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categoriesDirect
        this.spinner = false
      }
    })
  },

  methods: {
    filterByCategory(uuid) {
      this.faqs = this.faqsFullList.filter(item => item.categoryUUID == uuid)
    }
  }
}
</script>

<style scoped>
.categoriesWrapper {
  width: 100%;
  padding: 10px;
}
.categoriesWrapper button {
  border-radius: 30px;
  background-color: white;
  padding: 0.2rem 2rem;
  margin: 0 0.2rem 1rem;
}
.categoriesWrapper button:hover {
  background-color: #068a50;
}

.accordion .card .card-header {
  background-color: #fff;
  margin-bottom: -1px;
  padding: 0;
}
.accordion .card .card-body {
  background-color: #fff;
}
.accordion-button {
  background-color: #fff;
  font-size: 0.9rem;
  border-radius: 0;
  text-align: left;
  border: none;
}
.accordion-button:focus,
.accordion-button:active,
.accordion-button:hover {
  background-color: #fff;
  box-shadow: none;
  border: none;
}

.faqHeader {
  background-color: #eff3f9 !important;
  border: solid 1px #cfd3d8;
  border-bottom: none;
}
</style>
