<template>
  <div id="Agent">
    <NOUNTop v-show="!this.$store.state.webview" />
    <b-navbar toggleable="lg" type="light" variant="light">
      <router-link class="navbar-brand text-muted" :to="{ name: 'HomeSupervisor' }">Second Level</router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link :to="{ name: 'HomeSupervisor' }" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li>
          <!-- <li class="nav-item">
              <router-link to="/supervisor/tickets/open" class="nav-link">
              <b-icon-circle-fill class="text-danger"></b-icon-circle-fill>
              Open
            </router-link>
          </li>-->
          <li class="nav-item">
            <router-link to="/supervisor/tickets/in_progress" class="nav-link">
              <b-icon-circle-fill class="text-primary"></b-icon-circle-fill>
              InProgress
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/supervisor/tickets/escalated" class="nav-link">
              <b-icon-circle-fill class="text-warning"></b-icon-circle-fill>
              Escalated
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/supervisor/tickets/closed" class="nav-link">
              <b-icon-circle-fill class="text-success"></b-icon-circle-fill>
              Closed
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/supervisor/tickets/reopened" class="nav-link">
              <b-icon-circle-fill class="text-info"></b-icon-circle-fill>
              Reopened
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'AgentsListSupervisor' }" class="nav-link">
              <b-icon-people></b-icon-people>
              Agents
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <notifications />
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name }} </template>

            <b-dropdown-item :to="{ name: 'ProfileSupervisor' }">
              <b-icon-person></b-icon-person>
              Profile
            </b-dropdown-item>

            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import NOUNTop from '@/components/NOUNTop.vue'
import Logout from '@/components/Logout.vue'
import Notifications from '../components/Notifications.vue'

export default {
  name: 'SupervisorLayout',

  components: {
    NOUNTop,
    Logout,
    Notifications
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
