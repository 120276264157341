<template>
  <div id="FAQAdmin" class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="pageTitle">
          FAQs <small>({{ totalRecords }})</small>
        </div>
      </div>
      <div class="col-6">
        <select class="form-control form-control-lg" @change="filterByCategory($event)">
          <option value="Select..." selected>Select...</option>
          <option v-for="item in categories" :key="item.uuid" :value="item.uuid">{{ item.name }}</option>
        </select>
      </div>
    </div>

    <!--  -->
    <!--  -->
    <div class="row" v-show="showList">
      <div class="col-12">
        <!--  -->
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-3" v-for="(item, index) in faqs" :key="index">
            <b-card-header header-tag="header" class="p-1" role="tab" style="background-color: #DEE0E5;">
              <b-button block v-b-toggle="'accordion-' + index" variant="light" class="accordion-button" style="color:#555; background-color: #DEE0E5;">
                <b-icon-question-circle class="questionIcon"></b-icon-question-circle>
                <span>{{ item.question }}</span>
              </b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-icon-book class="bookIcon"></b-icon-book>
                <div>
                  <p>
                    <label class="text-success" style="font-weight:bold;">{{ item.categoryName }}</label>
                  </p>
                  <div v-html="item.answer"></div>
                </div>
                <span v-if="authUserRole == 'admin'">
                  <EditForm :faqIndex="index" :faqObject="item" v-if="editFormIndex == index" @hideBack="hideEditForm" :categories="categories"></EditForm>
                  <DeleteFAQ :faqIndex="index" :faqObject="item" v-if="deleteFaqIndex == index" @deleted="hideDeleteFAQ"></DeleteFAQ>
                </span>
              </b-card-body>
              <b-card-footer v-if="authUserRole == 'admin'">
                <button class="btn btn-xs" @click="showEditForm(index)">Edit</button>
                <button class="btn btn-xs" @click="showDeleteFAQ(index)">Delete</button>
              </b-card-footer>
            </b-collapse>
          </b-card>
        </div>
        <!--  -->
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="row" v-if="authUserRole == 'admin'">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-body">
            <!-- create for -->
            <CreateForm @newFAQ="newFAQ" :categories="categories"></CreateForm>
            <!-- create for -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EditForm from './EditForm'
import CreateForm from './CreateForm'
import DeleteFAQ from './DeleteFAQ'

export default {
  name: 'FAQAdmin',

  data() {
    return {
      faqs: [],
      faqsFullList: [],
      showList: false,
      totalRecords: 0,
      editFormIndex: -1,
      deleteFaqIndex: -1,
      categories: []
    }
  },

  mounted() {
    let loading = this.$loading.show()
    // categories
    axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categoriesDirect
      }
    })
    // faqs
    axios.get(`/${this.authUserRole}/faqs`).then(res => {
      if (res.data.status == 'success') {
        this.faqs = res.data.faqs
        this.faqsFullList = res.data.faqs
        this.totalRecords = res.data.totalRecords
        this.showList = true ? res.data.faqs.length > 0 : false
      }
      loading.hide()
    })
  },

  methods: {
    newFAQ(newFAQ) {
      this.faqs.push(newFAQ)
      this.showList = true
    },

    showEditForm(index) {
      this.editFormIndex = index
    },

    hideEditForm(obj) {
      this.editFormIndex = -1
      this.faqs[obj.updatedFaqIndex].answer = obj.updatedFaqObj.answer
      this.faqs[obj.updatedFaqIndex].question = obj.updatedFaqObj.question
    },

    showDeleteFAQ(index) {
      this.deleteFaqIndex = index
      //console.log(index);
    },

    hideDeleteFAQ(obj) {
      this.deleteFaqIndex = -1
      this.faqs.splice(obj.deletedFaqIndex, 1)
    },

    filterByCategory(e) {
      const uuid = e.target.value
      if (uuid == 'Select...') {
        return
      }
      this.faqs = this.faqsFullList.filter(item => item.categoryUUID == uuid)
    }
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },
    client() {
      return this.$store.state.client
    },
    authUserRole() {
      return this.$store.state.authUser.Role
    }
  },

  components: {
    CreateForm,
    DeleteFAQ,
    EditForm
  }
}
</script>

<style scoped>
/*  */

.accordion .card .card-header {
  background-color: #2b972b;
  margin-bottom: -1px;
  padding: 0;
}

.accordion-button {
  background-color: #2b972b;
  font-size: 0.9rem;
  border-radius: 0;
  text-align: left;
  border: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accordion-button:hover {
  background-color: #2b972b;
}
.accordion-button:focus {
  background-color: #fff;
  box-shadow: none;
  border: none;
  background-color: #2b972b;
}

.accordion .card .card-body {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: top;
}

#search {
  padding: 2rem 3rem;
  border-radius: 5rem;
  font-size: 1.5rem;
}

@media (min-width: 200px) {
  .questionIcon {
    font-size: 1rem !important;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
  .bookIcon {
    font-size: 2.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .questionIcon {
    font-size: 2rem !important;
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bookIcon {
    font-size: 5rem;
    margin-right: 3rem;
  }
}
</style>
