<template>
  <div id="CreateUserAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Create new user</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Please provide user details</div>
          <div class="card-body">
            <div class="col-md-8">
              <!-- <h3>Please provide user details</h3> -->
              <!-- Create form -->
              <form @submit.prevent="createUser">
                <div class="form-group">
                  <label for="role">Roles</label>
                  <select v-model="roleSlug" :class="{ 'is-invalid': $v.roleSlug.$error, 'is-valid': !$v.roleSlug.$invalid }" class="form-control" @change="roleSelected($event)">
                    <option selected value>Select ...</option>
                    <option v-for="role in roles" :key="role.slug" :value="role.slug">
                      <!-- {{role.name}} -->
                      <span v-if="role.slug === 'user'">Student</span>
                      <span v-else>{{ role.name }}</span>
                      <!-- <span v-if="role.slug === 'admin'">Admin</span>
                      <span v-if="role.slug === 'agent'">Agent</span>
                      <span v-if="role.slug === 'custom'">Custom</span>
                      <span v-if="role.slug === 'user'">Student</span>
                      <span v-if="role.slug === 'supervisor'">Supervisor</span> -->
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <span v-if="!$v.roleSlug.required">Role is required.</span>
                  </div>
                </div>

                <div class="form-group" v-if="matricField">
                  <label for="matric">Matric Number</label>
                  <input type="text" v-model="matric" class="form-control " placeholder="e.g. NOU123456789" :class="{ 'is-invalid': $v.matric.$error, 'is-valid': !$v.matric.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.matric.required">Matric is required.</span>
                    <span v-if="!$v.matric.minLength">Matric must be at least {{ $v.matric.$params.minLength.min }} letters.</span>
                    <span v-if="!$v.matric.maxLength">Matric must be at most {{ $v.matric.$params.maxLength.max }} letters.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" v-model="name" class="form-control" placeholder="e.g. John Smith" :class="{ 'is-invalid': $v.name.$error, 'is-valid': !$v.name.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.name.required">Name is required.</span>
                    <span v-if="!$v.name.minLength">Name must be at least {{ $v.name.$params.minLength.min }} letters.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" v-model="email" class="form-control" placeholder="e.g. john@gmail.com" :class="{ 'is-invalid': $v.email.$error, 'is-valid': !$v.email.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.email.required">Email is required.</span>
                    <span v-if="!$v.email.email">Email must be valid email address.</span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="phone">Phone number</label>
                  <input type="text" v-model="phone" class="form-control" placeholder="e.g. 08011223344" :class="{ 'is-invalid': $v.phone.$error, 'is-valid': !$v.phone.$invalid }" />
                  <div class="invalid-feedback">
                    <span v-if="!$v.phone.required">Phone is required.</span>
                    <span v-if="!$v.phone.numeric">Phone must be numeric.</span>
                    <span v-if="!$v.phone.minLength">Phone must be at least {{ $v.phone.$params.minLength.min }} digits.</span>
                    <span v-if="!$v.phone.maxLength">Phone must be at most {{ $v.phone.$params.maxLength.max }} digits.</span>
                  </div>
                </div>

                <button class="btn btn-danger btn-block">Create new user</button>
              </form>
              <!-- / Create form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'CreateUserAdmin',

  data() {
    return {
      roles: [],
      roleSlug: '',
      name: '',
      email: '',
      phone: '',
      matric: '',
      matricField: false
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/admin/roles').then(res => {
      this.roles = res.data.roles
      loading.hide()
    })
  },

  methods: {
    createUser() {
      let loading = this.$loading.show()
      this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      }
      if (this.matric == '') {
        this.matric = this.email // use email as matric for non student role
      }
      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('email', this.email)
      formData.append('phone', this.phone)
      formData.append('role', this.roleSlug)
      formData.append('matric', this.matric)
      //formData.append('originURL', window.location.origin)
      axios.post('admin/user/create', formData).then(res => {
        if (res.data.status === 'success') {
          this.$v.$reset()
          this.$swal('', res.data.message, 'success')
          if (this.roleSlug == 'user') {
            this.$router.push({ name: 'StudentsAdmin' })
          } else {
            this.$router.push({ name: 'UsersAdmin', params: { role: this.roleSlug } })
          }
          this.name = ''
          this.email = ''
          this.phone = ''
          this.roleSlug = ''
        } else {
          //console.log(res.data);
          this.$swal.fire({ title: '', html: res.data.message, icon: 'error' })
        }
        loading.hide()
      })
    },

    roleSelected(e) {
      let role = e.target.value
      if (role == 'user') {
        this.matricField = true
      } else {
        this.matricField = false
      }
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  /* validations: {
    name: { required, minLength: minLength(2) },
    email: { required, email },
    phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) },
    roleSlug: { required },
  } */

  validations() {
    let obj = {
      name: { required, minLength: minLength(2) },
      email: { required, email },
      phone: { required, numeric, minLength: minLength(11), maxLength: maxLength(11) },
      roleSlug: { required }
      //matric: { required }
    }
    if (this.matricField) {
      obj.matric = { required, minLength: minLength(12), maxLength: maxLength(12) }
    }
    return obj
  }
}
</script>
