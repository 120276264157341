<template>
  <div id="CreateUserAdmin" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Purge Graduates</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Remove graduates from the system</div>
          <div class="card-body">
            <div v-if="preview">
              <h1>Data Preview</h1>
              <p class="text-success">Total records: {{ students.length }}</p>
              <p class="text-danger">Please verify the accuracy of the data, and then click the purge button below.</p>
              <!-- preview table -->
              <table class="table table-sm table-bordered monofont">
                <thead>
                  <tr>
                    <th>matric</th>
                    <th>name</th>
                    <th>faculty</th>
                    <th>programme</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in students" :key="index">
                    <td>{{ student.Matric }}</td>
                    <td>{{ student.Name }}</td>
                    <td>{{ student.Faculty.Name }}</td>
                    <td>{{ student.Programme.Name }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- / preview table -->
              <p>
                <button @click="purge" class="btn btn-danger btn-lg">Yes, purge</button>
              </p>
            </div>

            <!-- For uploading -->
            <div v-else>
              <p class="text-danger">
                ONLY CSV IS ALLOWED. As seen below, a single column table with students' matric numbers.
              </p>

              <table class="table table-sm table-bordered monofont" style="width: 200px;">
                <thead>
                  <tr>
                    <th>matric</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NOU000000001</td>
                  </tr>
                  <tr>
                    <td>NOU000000002</td>
                  </tr>
                  <tr>
                    <td>NOU000000003</td>
                  </tr>
                  <tr>
                    <td>NOU000000004</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Dropzone -->
            <div class="form-group mt-5 mb-5" v-if="!preview">
              <b-icon-card-list></b-icon-card-list> <label for="att">upload csv file</label>
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                :useCustomSlot="true"
                :options="dropzoneOptions"
                @vdropzone-error="uploadError"
                @vdropzone-success="uploadSuccess"
                @vdropzone-file-added="uploadFileAdded"
              >
                <div class="dropzone-custom-content" v-html="dropzoneOptions.myCustomContent"></div>
              </vue-dropzone>
            </div>
            <!-- Dropzone -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
//import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'StudentsPurgedAdmin',

  data() {
    return {
      spinner: false,
      preview: false,
      fileName: '',
      matrics: [],
      students: []
    }
  },

  mounted() {},

  methods: {
    uploadError(file) {
      this.spinner = false
      this.preview = false
      this.$swal('Oops!', file.fileName + ' not uploaded.', 'error')
    },

    uploadSuccess(file, response) {
      this.preview = true
      this.fileName = response.fileName
      this.students = response.students
      this.matrics = response.matrics
      this.spinner = false
      console.log(response)
    },

    uploadFileAdded(file) {
      this.spinner = true
    },

    purge() {
      this.spinner = true
      axios
        .post(`/admin/students-purge?fileName=${this.fileName}`)
        .then(res => {
          this.$swal('Success!', res.data.message, 'success')
          alert(res.data.message)
          console.log(res.data.numberOfFailed)
          window.location.reload()
        })
        .catch(error => {
          this.$swal('Oops!', 'Something went wrong.', 'error')
          window.location.reload()
        })
    }
  },

  computed: {
    dropzoneOptions() {
      return {
        thumbnailHeight: 80,
        maxFilesize: 2 ** 22, // 1073741824 bytes  1GB
        url: process.env.VUE_APP_API_URL + '/admin/students-purge',
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
          Authorization: 'Bearer ' + this.$store.state.authToken
        },
        acceptedFiles: 'text/csv',
        myCustomContent: '<span class="vdContent-title">Drag and drop to upload files here!</span>'
      }
    },

    client() {
      return this.$store.state.client
    }
  },

  components: {
    vueDropzone: vue2Dropzone
  }
}
</script>

<style scoped>
.monofont {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 0.85rem;
}
</style>
