<template>
  <div class="">
    <div class="sidebar" :class="compress ? 'compress' : ''">
      <div class="logo-details">
        <!-- <i class="bx bxl-c-plus-plus"></i> -->
        <div class="logo_icon">
          <img src="../assets/noun-logo.png" alt="" width="50" height="50" />
        </div>
        <div class="logo_name">Support</div>
      </div>
      <ul class="nav-links">
        <!-- Dashboard -->
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon-house-door></b-icon-house-door>
            <span class="link_name">Dashboard</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Dashboard</a></li>
          </ul>
        </li>

        <!-- Category -->
        <li @click="showSubMenu('Tickets')" :class="menu == 'Tickets' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-stickies></b-icon-stickies>
              <span class="link_name">Tickets</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Tickets</a></li>
            <li><router-link to="/admin/tickets/open">Open</router-link></li>
            <li><router-link to="/admin/tickets/in_progress">In Progress</router-link></li>
            <li><router-link to="/admin/tickets/escalated">Escalated</router-link></li>
            <li><router-link to="/admin/tickets/closed">Closed</router-link></li>
            <li><router-link to="/admin/tickets/reopened">Reopened</router-link></li>
            <li><router-link to="/admin/tickets/all">All</router-link></li>
          </ul>
        </li>

        <li>
          <router-link :to="{ name: 'FAQAdmin' }">
            <b-icon-question-circle></b-icon-question-circle>
            <span class="link_name">FAQs</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">FAQs</a></li>
          </ul>
        </li>

        <!-- Categories -->
        <li @click="showSubMenu('Categories')" :class="menu == 'Categories' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-layers></b-icon-layers>
              <span class="link_name">Categories</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Categories</a></li>
            <li><router-link :to="{ name: 'CreateCategoryAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'CategoriesAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Subcategories -->
        <li @click="showSubMenu('Subcategories')" :class="menu == 'Subcategories' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-layers-half></b-icon-layers-half>
              <span class="link_name">Subcategories</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Subcategories</a></li>
            <li><router-link :to="{ name: 'CreateSubCategoryAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'SubCategoriesAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Custom Roles -->
        <li @click="showSubMenu('CustomRoles')" :class="menu == 'CustomRoles' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-folder></b-icon-folder>
              <span class="link_name">Custom Roles</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Custom Roles</a></li>
            <li><router-link :to="{ name: 'CreateCustomRoleAdmin' }">Create</router-link></li>
            <li><router-link :to="{ name: 'CustomRolesAdmin' }">View</router-link></li>
          </ul>
        </li>

        <!-- Custom Roles -->
        <li @click="showSubMenu('Users')" :class="menu == 'Users' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-people></b-icon-people>
              <span class="link_name">Users</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Users</a></li>
            <li><router-link to="/admin/user/create">Create new user</router-link></li>
            <li><router-link to="/admin/users/admin">Admins</router-link></li>
            <li><router-link to="/admin/users/manager">Managers</router-link></li>
            <li><router-link to="/admin/users/agent">Agents</router-link></li>
            <li><router-link to="/admin/users/custom">Customs</router-link></li>
            <li><router-link to="/admin/users/supervisor">Supervisors</router-link></li>
            <li><router-link :to="{ name: 'StudentsAdmin' }">Students</router-link></li>
            <!-- <li><router-link :to="{ name: 'StudentsAdmin' }">Purge graduate</router-link></li> -->
          </ul>
        </li>

        <!--  <li @click="showSubMenu('Logs')" :class="menu == 'Logs' ? 'showMenu' : ''">
          <div class="iocn-link">
            <a href="#">
              <b-icon-journal-code></b-icon-journal-code>
              <span class="link_name">Logs</span>
            </a>
            <b-icon-chevron-down class="arrow"></b-icon-chevron-down>
          </div>
          <ul class="sub-menu">
            <li><a href="#" class="link_name">Logs</a></li>
            <li><router-link :to="{ name: 'TicketsLog' }">Tickets Log</router-link></li>
            <li><router-link :to="{ name: 'UsersLog' }">Users Log</router-link></li>
          </ul>
        </li>
 -->
        <li>
          <router-link :to="{ name: 'UsersLog' }">
            <b-icon-journal-code></b-icon-journal-code>
            <span class="link_name">Audit Trail</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Audit Trail</a></li>
          </ul>
        </li>
        <!-- Announcement -->
        <li>
          <router-link :to="{ name: 'Announcement' }">
            <b-icon-bell></b-icon-bell>
            <span class="link_name">Announcement</span>
          </router-link>
          <ul class="sub-menu blank">
            <li><a class="link_name" href="#">Announcement</a></li>
          </ul>
        </li>
        <!-- profile -->
        <li>
          <div class="profile-details">
            <router-link :to="{ name: 'ProfileAdmin' }">
              <div class="profile-content">
                <img src="../assets/default.png" alt="profile" width="52" height="52" />
              </div>

              <div class="name-job">
                <div class="profile_name">{{ authUser.Name }}</div>
                <div class="job">{{ authUser.Role }}</div>
              </div>
            </router-link>

            <b-icon-box-arrow-left title="Logout" @click="Logout"></b-icon-box-arrow-left>
            <!-- <i class="bx bx-log-out" ></i> -->
          </div>
        </li>
      </ul>
    </div>

    <section class="home-section">
      <div class="home-content">
        <!-- <i class="bx bx-menu" @click="toggleMenu()"></i> -->
        <b-icon-list @click="toggleMenu()"></b-icon-list>
        <span class="text"></span>
      </div>
      <div class="cont">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AdminLayout',

  data() {
    return {
      compress: false,
      menu: ''
    }
  },

  mounted() {
    if (window.innerWidth < 768) {
      this.compress = true
    }
  },

  created() {
    window.addEventListener('resize', this.screenResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.screenResize)
  },

  methods: {
    screenResize(e) {
      //console.log(e.srcElement.innerWidth);
      /* console.log(screen.width); */
      if (e.srcElement.innerWidth < 768) {
        this.compress = true
      } else {
        this.compress = false
      }
    },

    toggleMenu() {
      this.compress = !this.compress
    },

    showSubMenu(menu) {
      if (this.menu == menu) {
        this.menu = ''
      } else {
        this.menu = menu
      }
    },

    Logout() {
      axios.post('/auth/logout').then(res => {
        if (res.data.status == 'success') {
          this.$store.state.authUser = {}
          this.$store.state.authToken = ''
          window.localStorage.removeItem('log_uuid')
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.VUE_APP_API_KEY
          this.$swal('', 'Logged out successfully.', 'info')
          this.$router.push('/login')
        }
      })
    }

    /*  */
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    }
  }
}
</script>

<style scoped></style>
