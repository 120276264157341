<template>
  <div id="app">
    <router-view />
    <footer class="footer" v-show="!this.$store.state.webview">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <span class="text-muted">&copy; {{ year }} National Open University of Nigeria. All rights reserved.</span>
          </div>
          <div class="col-6 text-right">
            <span class="text-muted">Powered by <a href="http://coregrade.co" target="_blank">Coregrade</a>.</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('getWebview')
  },

  computed: {
    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.footer {
  font-size: 0.8rem;
}
</style>
