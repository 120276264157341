<template>
  <div class="container-fluid" id="CreateSubCategoryAdmin">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">Announcement</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Please provide announcement message</div>
          <div class="card-body">
            <div class="col-md-8">
              <h3></h3>
              <!-- Create form -->
              <form @submit.prevent="createAnnouncement">
                <!-- Instruction -->
                <div class="form-group">
                  <label for="announcement">Announcement message</label>
                  <vue-editor v-model="announcementBody" :editorToolbar="editorToolbar"></vue-editor>
                  <!-- <div class="text-danger text-xs" v-show="$v.announcementBody.$error">
                    <span v-if="!$v.announcementBody.required">Announcement message is required.</span>
                    <span v-if="!$v.announcementBody.minLength">
                      Announcement message must be at least {{ $v.announcementBody.$params.minLength.min }} letters.
                    </span>
                  </div> -->
                </div>

                <!--  -->

                <button class="btn btn-danger btn-block">Submit</button>
              </form>
              <!-- / Create form -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, minLength } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CreateSubCategoryAdmin',

  data() {
    return {
      announcementBody: '',

      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  },

  mounted() {
    let loading = this.$loading.show()
    axios.get('/admin/announcement').then(res => {
      this.announcementBody = res.data.announcement.body
      loading.hide()
    })
  },

  methods: {
    createAnnouncement() {
      let loading = this.$loading.show()
      /* this.$v.$touch()
      if (this.$v.$invalid) {
        loading.hide()
        return
      } */
      let formData = new FormData()
      formData.append('announcementBody', this.announcementBody)

      axios.post('admin/announcement', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('', res.data.message, 'success')
        } else {
          this.$swal('', res.data.message, 'error')
        }
        loading.hide()
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    }
  },

  created() {
    //this.$store.commit("SET_LAYOUT", "agent-layout");
  },

  validations: {
    //announcementBody: { required, minLength: minLength(2) }
  },

  components: {
    VueEditor
  }
}
</script>
