<template>
  <span>
    <button @click="forcePasswordReset" class="btn btn-outline-secondary btn-xs ml-3">Force password reset</button>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForcePasswordReset',

  props: { user: Object },

  methods: {
    forcePasswordReset() {
      /*   */

      this.$swal
        .fire({
          title: 'Are you sure?',
          text: `You are about to reset ${this.user.name}'s password`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, reset it!'
        })
        .then(result => {
          if (result.value) {
            /* actural action */
            let loading = this.$loading.show()

            axios.post(`/admin/user/force-password-reset/${this.user.uuid}`).then(res => {
              if (res.data.status == 'success') {
                this.$swal.fire('Done!', res.data.message, 'success')
              } else {
                this.$swal('', res.data.message, 'error')
              }
              loading.hide()
            })
            /* end of actural action */
          }
        })
    }
  }
}
</script>

<style></style>
